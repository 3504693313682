import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Reveal from "../motion/Reveal";
import ProductCard from "../common/ProductCard";
import { httpReauest } from "../../utils/httprequest";
import { FaAngleLeft } from "react-icons/fa";
import ProductCardAudio from "../common/ProductCardAudio";

const TopProducts = () => {
  const [data, setData] = useState();
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const newData = await httpReauest("GET", "/product/top", {}, {});

    setData(newData.data.data);
  }
  return (
    <div className="u-s-p-b-60 u-s-p-t-60">
      <div className="section__intro  ">
        <div className="container ">
          <div>
            <Reveal head>
              <div className="w-full flex justify-center text-[#1A1A1A]   pb-6">
                <span className="bg-[#6FDCDB] rounded-full h-[35px] px-4">
                  <h2 className="text-[32px] lg:text-[40px]  font-bold relative -top-3">
                    Audio Books
                  </h2>
                </span>
              </div>
            </Reveal>
          </div>
        </div>
      </div>

      <div className="section__content ">
        <div className="container">
          <div className="row ">
            <div className="col-lg-12">
              <div className="filter__grid-wrapper u-s-m-y-10">
                <div className="flex overflow-x-scroll lg:overflow-hidden">
                  {data?.map((e) => {
                    return <ProductCardAudio data={e} home />;
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center">
            <Link
              to={"/products"}
              className="btn--e-primary py-2 px-6 text-[18px]"
            >
              بیشتر
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopProducts;
