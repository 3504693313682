import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import Reveal from "../motion/Reveal";
import { FaAngleLeft } from "react-icons/fa6";

const Slider = () => {
  return (
    <div class="  ">
      <div className="lg:h-[90vh] relative py-6 px-4  lg:px-12 xl:px-20 2xl:px-32">
        <div className="relative lg:static">
          <video
            autoPlay
            playsInline
            loop
            className="lg:absolute left-0 top-0 w-full h-full object-cover rounded-[16px] lg:rounded-none"
            src="/video/landing.mp4"
            alt="Slider Video"
            muted
            preload="auto"
          />
          <span
            style={{
              background:
                "linear-gradient(180deg, rgba(0, 0, 0, 0) 13.53%, #000000 95.42%)",
            }}
            className="w-full h-full absolute left-0 top-0 z-10 rounded-[16px] lg:rounded-none"
          ></span>
          <div className="absolute top-[55%] md:top-[45%] translate-y-[-50%] right-[20px] lg:right-[8%] w-full z-20   text-white">
            <Swiper
              modules={[Autoplay]}
              autoplay={{ delay: 4000 }}
              loop
            >
              <SwiperSlide>
                <div className=" grid pe-10 md:grid-cols-2 ">
                  <span>
                    <h2 className="font-bold text-[18px] md:text-[30px]  lg:text-[50px] pe-5">
                      مجموعه کتاب های صوتی برای پیشرفت
                    </h2>
                    <Link
                      to={"/signin"}
                      className="w-fit  px-4 lg:px-6 py-1 lg:py-2 rounded-lg lg:text-[16px] text-[12px] btn--e-brand flex justify-center items-center mt-3 lg:mt-5"
                    >
                      مشترک شو
                    </Link>
                  </span>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=" grid pe-10 md:grid-cols-2 ">
                  <span>
                    <h2 className="font-bold text-[18px] md:text-[30px]  lg:text-[50px] pe-5">
                      بستری برای ارتقــــــــــــاء توانمندی ها و پیشرفت
                      شخصـــــــی و حرفــــــــه ای
                    </h2>
                    <Link
                      to={"/signin"}
                      className="w-fit  px-4 lg:px-6 py-1 lg:py-2 rounded-lg lg:text-[16px] text-[12px] btn--e-brand flex justify-center items-center mt-3 lg:mt-5"
                    >
                      پیشرفت رو با ما شروع کن
                    </Link>
                  </span>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          {/* <span className="absolute left-1/2 z-20 bottom-2 lg:bottom-8 text-white -translate-x-1/2 gap-1 flex-wrap text-[24px] lg:text-[42px]">
            <Reveal>
              <div className="text-[16px] lg:text-[54px] whitespace-nowrap">
                UNLOCKING THE SECRETS
              </div>
              <span className="text-[32px] lg:text-[108px] font-bold">
                TO{" "}
                <span className="border-b-[6px] border-[#57F39B]">SUCCESS</span>
              </span>
            </Reveal>
          </span> */}
        </div>
      </div>
      <div className="flex-col flex justify-center items-center pb-4">
        <Reveal head>
          <h1 className="text-[#0A0A0A] text-[38px] lg:text-[52px] text-center">
            'سیریر'
          </h1>
        </Reveal>

        <Reveal>
          <div className="bg-[#0A0A0A] rounded-full p-2 flex items-center gap-5 text-[12px] lg:text-[22px] text-white mb-2">
            <span>
              بستری برای ارتقــــــــــــاء توانمندی ها و پیشرفت شخصـــــــی و
              حرفــــــــه ای
            </span>
            <Link className="bg-[#03C976] rounded-full p-3 text-white text-[16px] items-center gap-2 px-4 hidden lg:flex">
              پیشرفت رو با ما شروع کن
              <FaAngleLeft />
            </Link>
          </div>
        </Reveal>
        <Reveal>
          <Link className="bg-[#03C976] rounded-full p-2 text-white text-[14px] items-center gap-2 px-4 flex lg:hidden">
            پیشرفت رو با ما شروع کن
            <FaAngleLeft />
          </Link>
        </Reveal>
      </div>
      {/* 
      <span className="flex gap-7 flex-col z-10">
        <Reveal head></Reveal>
        <Reveal head>
          <Link
            to={"/sign"}
            className=" text-[20px] p-3 cursor-pointer bg-[--brand] text-white font-[Shabnam-thin] flex justify-center items-center rounded-[25px] gap-1"
          >
            پیشرفت رو با ما شروع کن
            <FaAngleLeft size={22} />
          </Link>
        </Reveal>
      </span> */}
    </div>
  );
};

export default Slider;
