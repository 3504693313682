import React from "react";
import { Link } from "react-router-dom";

const TextWelcome = () => {
  return (
    <div className=" py-16  relative  text-center px-3 bg-[#fff] grid lg:grid-cols-2 lg:px-20 items-center gap-5 lg:gap-0">
      <span className="text-[#131734]  flex flex-col gap-2 lg:gap-8 items-center">
        <p className="text-[20px] lg:text-[28px] font-bold">
          مجموعه کتاب های صوتی برای پیشرفت
        </p>
        <h3
          style={{ textShadow: " 2px 2px  #57F39B66" }}
          className="text-[#03C976] text-[30px] lg:text-[54px] font-bold mb-4 mt-2"
        >
          در زندگی شخصـی و حرف‍‍ـه ای
        </h3>
        <p className="text-[20px] lg:text-[28px] font-bold hidden lg:block">
          فقط با تهیه اشتراک
        </p>
        <Link
          to={"/signin"}
          className="btn--e-primary py-2 px-6 text-[18px] hidden lg:block"
        >
          مشترک شو!
        </Link>
      </span>{" "}
      <span>
        <img src="/books.png" className="w-full" alt="Sirir Books" />
      </span>
      <span className="lg:hidden text-[#131734]">
        <p className="text-[20px] lg:text-[28px] font-bold mb-5">
          فقط با تهیه اشتراک
        </p>
        <Link
          to={"/signup"}
          className="btn--e-primary py-2 px-6 text-[18px]"
        >
          مشترک شو!
        </Link>
      </span>
    </div>
  );
};

export default TextWelcome;
